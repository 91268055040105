import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import AuthService from "../services/auth.service";

interface AuthContextType {
  user: User | null;
  loading: boolean;
  login: (userData: User) => void;
  logout: () => void;
}

export interface User {
  user_id: string;
  token: string;
  user_name: string;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(JSON.parse(localStorage.getItem("user") ?? "null"));
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const storedUser = window.localStorage.getItem("user");
    console.log(storedUser);
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    AuthService.updateToken(localStorage.getItem("token"));

    setLoading(false);
  }, []);

  const login = (userData: User) => {
    if (userData) {
      setUser(userData);
      window.localStorage.setItem("user", JSON.stringify(userData));
      localStorage.setItem("token", userData.token);
      AuthService.updateToken(userData.token);
    }
  };

  const logout = useCallback(() => {
    setUser(null);
    localStorage.clear();
    AuthService.updateToken(null);
  }, []); 

  useEffect(() => {
    AuthService.registerLogoutFn(logout);
  }, [logout]);

  return (
    <AuthContext.Provider value={{ user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
