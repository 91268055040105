import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { 
  Navbar as BsNavbar,
  Nav as BsNav,
  NavDropdown as BsNavDropdown,
  Image as BsImage
} from "react-bootstrap";

export const NavbarStyled = styled(BsNavbar)`
  background-color: #feeb9c;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0.8rem 1rem;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

export const NavbarBrandStyled = styled(BsNavbar.Brand)`
  display: flex;
  align-items: center;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-1px);
  }
`;

export const NavItemStyled = styled(BsNav.Item)`
  display: flex;
  align-items: center;
  padding: 0;
  margin-right: 1rem;
  position: relative;
  height: 40px; /* Altura fixa para todos os itens */

  &:last-child {
    margin-right: 0;
  }
`;

export const NavDropdownStyled = styled(BsNavDropdown)`
  font-weight: 600;
  margin-right: 1rem;
  height: 40px; /* Mesma altura dos outros itens */
  display: flex;
  align-items: center;

  .dropdown-toggle {
    color: rgba(0, 0, 0, 0.7) !important;
    transition: all 0.2s ease;
    padding: 0.25rem 0.5rem !important;
    height: 100%;
    display: flex;
    align-items: center;

    &::after {
      transition: transform 0.2s ease;
      margin-left: 0.5rem;
    }

    &:hover {
      color: rgba(0, 0, 0, 0.9) !important;
    }
  }
`;

export const NavDropdownItemStyled = styled(BsNav.Item)`
  padding: 0.5rem 1.5rem;
  transition: all 0.2s ease;
  font-weight: 500;

  &:hover {
    background-color: #ffd700;
  }

  .dropdown-link {
    color: rgba(0, 0, 0, 0.7) !important;
    text-decoration: none;
    width: 100%;
    display: block;

    &.active {
      color: rgba(0, 0, 0, 0.9) !important;
      font-weight: 700;
      background-color: transparent;
    }
  }

  .license-link {
    color: #e74c3c !important;
    &:hover {
      color: #c0392b !important;
    }
  }

  .logout-link {
    color: #e74c3c !important;
    &:hover {
      color: #c0392b !important;
    }
  }
`;

export const Image = styled(BsImage)`
  height: 35px;
  margin-right: 0.75rem;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

export const LinkStyled = styled(NavLink)`
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  transition: all 0.2s ease;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.7);
    transition: width 0.3s ease;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.9);
    
    &::after {
      width: 100%;
    }
  }

  &.active {
    color: rgba(0, 0, 0, 0.9);
    font-weight: 700;
    
    &::after {
      width: 100%; 
    }
  }
`;