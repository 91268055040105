import React, { useEffect, useMemo } from "react";
import AppRoutes from "./routes/Routes";
import Navbar from "./components/navbar/Navbar";
import GlobalStyles from "./GlobalStyles";
import { useAuth } from "./hooks/useAuth";
import styled from "styled-components";
import { Analytics } from "@vercel/analytics/react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingProvider } from "./hooks/LoadingContext";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const App: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Lista de páginas públicas (permitidas sem autenticação)
  const publicPages = useMemo(() => ["/login", "/signup", "/"], []);

  // Verifica se a página atual é pública
  const isPublicPage = publicPages.includes(location.pathname);

  useEffect(() => {
    // 🔹 Evita loops de redirecionamento
    if (user && isPublicPage) {
      navigate("/dashboard", { replace: true }); // ✅ Usa `replace: true` para não empilhar histórico
    } else if (!user && !isPublicPage) {
      navigate("/login", { replace: true }); // ✅ Redireciona para login sem empilhar no histórico
    }
  }, [user, isPublicPage, navigate]);

  return (
    <LoadingProvider>
      <AppWrapper>
        {user && <Navbar />}
        <GlobalStyles />
        <Analytics />
        <AppRoutes />
      </AppWrapper>
    </LoadingProvider>
  );  
};

export default App;
