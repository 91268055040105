import axiosNestInstance from "../services/axiosNestInstance";
import { formDataToObject } from "./common/formData.utils";


export const changePassword = async (
  data: FormData
): Promise<any> => {
  try {
    await axiosNestInstance.patch("/user/change-password", formDataToObject(data));
    return { success: true };
  } catch (error:any) {
    const message = error.response.data.message;
    if(error.status === 400) return { error: typeof message === 'string' ? message : message[0]};
    throw error;
  }
};
