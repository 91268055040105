import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import ChangePassword from "../pages/change-password/ChangePassword";
import ProtectedRoute from "../components/protected-route/ProtectedRoute";

const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const Products = lazy(() => import("../pages/products/products"));
const Financeiro = lazy(() => import("../pages/financeiro/Financeiro"));
const Servicos = lazy(() => import("../pages/services/services"));
const Home = lazy(() => import("../pages/home/Home"));
const Login = lazy(() => import("../pages/login/Login"));
const Profile = lazy(() => import("../pages/profile/Profile"));
const Signup = lazy(() => import("../pages/signup/Signup"));
const CreateLicense = lazy(() => import("../pages/create-license/CreateLicense"));
const Clientes = lazy(() => import("../pages/customers/customers"));
const Agendamento = lazy(() => import("../pages/agendamento/AppointmentManagement"));
const Planejamento = lazy(() => import("../pages/planning/Planning"));
const AppointmentEdit = lazy(() => import("../pages/appointment-edit/AppoinmentEdit"));
const GestorAgendamento = lazy(() => import("../pages/Schedule-Manager/ScheduleManager"));
const Vendas = lazy(() => import("../pages/Sales/QuickSaleManager"));
const Settings = lazy(() => import("../pages/settings/settings"));
const BusinessManagement = lazy(() => import("../pages/businessManagement/businessManagement"));
const Update = lazy(() => import("../pages/updatesPage/UpdatesPage"));
const Subscription = lazy(() => import("../pages/subscription/subscription"));

const Loading = () => <div>Loading...</div>;

const ProtectedRouteElement = (Component: React.FC) => {
  return (
    <ProtectedRoute>
      <Component />
    </ProtectedRoute>
  );
};

const AppRoutes: React.FC = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        {/* 🔹 Organizando rotas protegidas */}
        <Route path="/profile" element={ProtectedRouteElement(Profile)} />
        <Route path="/dashboard" element={ProtectedRouteElement(Dashboard)} />
        <Route path="/financeiro" element={ProtectedRouteElement(Financeiro)} />
        <Route path="/createLicense" element={ProtectedRouteElement(CreateLicense)} />
        <Route path="/products" element={ProtectedRouteElement(Products)} />
        <Route path="/servicos" element={ProtectedRouteElement(Servicos)} />
        <Route path="/clientes" element={ProtectedRouteElement(Clientes)} />
        <Route path="/agendamento/:id" element={ProtectedRouteElement(AppointmentEdit)} />
        <Route path="/agendamento" element={ProtectedRouteElement(Agendamento)} />
        <Route path="/planejamento" element={ProtectedRouteElement(Planejamento)} />
        <Route path="/gestor-agendamento" element={ProtectedRouteElement(GestorAgendamento)} />
        <Route path="/vendas" element={ProtectedRouteElement(Vendas)} />
        <Route path="/settings" element={ProtectedRouteElement(Settings)} />
        <Route path="/businessmanagement" element={ProtectedRouteElement(BusinessManagement)} />
        <Route path="/updates" element={ProtectedRouteElement(Update)} />
        <Route path="/subscription" element={ProtectedRouteElement(Subscription)} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
