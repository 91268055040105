import React, { useState, useCallback } from "react";
import { useAuth } from "../../hooks/useAuth";
import {
  Image,
  NavbarStyled,
  NavbarBrandStyled,
  LinkStyled,
  NavItemStyled,
  NavDropdownStyled,
  NavDropdownItemStyled,
} from "./Navbar.styles";
import logo from "../../images/logo/beehed-horizontal.png";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LicenseModal from "./LicenseModal";

const NavbarPage: React.FC = () => {  
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showLicenseModal, setShowLicenseModal] = useState(false);

  const handleLogout = useCallback(async () => {
    setShowProfileMenu(false);
    logout();
    navigate("/login");
  }, [logout, navigate]);

  const handleLicenseModal = useCallback(() => {
    setShowProfileMenu(false);
    setShowLicenseModal(true);
  }, []);

  if (!user) return null;

  return (
    <NavbarStyled expand="md" variant="dark">
      <NavbarBrandStyled className="d-flex align-items-center">
        <Image src={logo} alt="Logo" fluid />
      </NavbarBrandStyled>
      
      <Navbar.Toggle aria-controls="navbarNav" className="border-0" />
      
      <Navbar.Collapse id="navbarNav" className="justify-content-between">
        <Nav className="main-navigation">
          <NavItemStyled>
            <LinkStyled 
              to="/dashboard" 
              className={({ isActive }: { isActive: boolean }) =>
                `nav-link-hover ${isActive ? "active" : ""}`
              }
            >
              Dashboard
            </LinkStyled>
          </NavItemStyled>
          
          {[
            { path: "/financeiro", label: "Financeiro" },
            { path: "/products", label: "Produtos" },
            { path: "/servicos", label: "Serviços" },
            { path: "/clientes", label: "Clientes" },
            { path: "/vendas", label: "Vendas" },
            { path: "/updates", label: "Updates" },
          ].map((item) => (
            <NavItemStyled key={item.path}>
              <LinkStyled
                to={item.path}
                className={({ isActive }: { isActive: boolean }) =>
                  `nav-link-hover ${isActive ? "active" : ""}`
                }
              >
                {item.label}
              </LinkStyled>
            </NavItemStyled>
          ))}

          <NavDropdownStyled
            title="Agendamentos"
            id="agendamentosDropdown"
            className="dropdown-smooth"
          >
            {[
              { path: "/planejamento", label: "Planejamento" },
              { path: "/agendamento", label: "Agendar" },
              { path: "/gestor-agendamento", label: "Gestor de Agendamentos" },
            ].map((item) => (
              <NavDropdownItemStyled key={item.path}>
                <LinkStyled 
                  to={item.path} 
                  className="dropdown-link"
                  end={true}
                >
                  {item.label}
                </LinkStyled>
              </NavDropdownItemStyled>
            ))}
          </NavDropdownStyled>
        </Nav>

        <NavDropdownStyled
          className="profile-dropdown"
          align="end"
          title={
            <div className="profile-content">
              <span className="profile-text">
                {user.user_name}
              </span>
            </div>
          }
          show={showProfileMenu}
          onToggle={setShowProfileMenu}
        >
          <div className="dropdown-menu-content">
            {[
              { path: "/changePassword", label: "Trocar senha" },
              { path: "/businessmanagement", label: "Gestão de Negócios" },
              { path: "/settings", label: "Configurações" },
              { path: "/subscription", label: "Planos" },
            ].map((item) => (
              <NavDropdownItemStyled key={item.path}>
                <LinkStyled to={item.path} className="dropdown-link">
                  {item.label}
                </LinkStyled>
              </NavDropdownItemStyled>
            ))}
            
            <NavDropdownItemStyled onClick={handleLicenseModal}>
              <span className="dropdown-link license-link">
                Gerenciar licença
              </span>
            </NavDropdownItemStyled>
            
            <NavDropdown.Divider className="dropdown-divider" />
            
            <NavDropdownItemStyled onClick={handleLogout}>
              <span className="dropdown-link logout-link">
                Logout
              </span>
            </NavDropdownItemStyled>
          </div>
        </NavDropdownStyled>
      </Navbar.Collapse>

      <LicenseModal 
        show={showLicenseModal} 
        handleModalHide={() => setShowLicenseModal(false)} 
      />
    </NavbarStyled>
  );
};

export default NavbarPage;