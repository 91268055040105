class AuthService {
    static getToken(): string | null {
      return localStorage.getItem("token");
    }
  
    static setToken(token: string): void {
      localStorage.setItem("token", token);
    }
  
    static logout(): void {
      localStorage.removeItem("token");
      window.location.href = "/login"; // Redireciona para login
    }
  }
  
  export default AuthService;
  