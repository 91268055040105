import axiosNestInstance from "../services/axiosNestInstance";
import { formDataToObject } from "./common/formData.utils";

export const addLicense  = async (formData: FormData) => {
  const body = formDataToObject(formData);
  try {
    await axiosNestInstance.post("/license/create-license", body);
  } catch (error:any) {
    console.log("Erro ao criar licença", error);
    throw error;
  }
};



export interface LicenseResponse {
  error?: string;
  licenseId?: string;
  expirationDate?: string;
}
export const getLicense  = async (): Promise<LicenseResponse> => {  
  try {
    const response = await axiosNestInstance.get("/license/get-license");

    return {
      error: response.data ? undefined : "Sem licença",
      licenseId: response.data?.license_id,
      expirationDate: response.data?.data_expiracao,
    };
  } catch (error:any) {
    const message = error.response.data.message;    
    return { 
      error: typeof message === 'string' ? message : message[0],
      licenseId: undefined,
      expirationDate: undefined,
    };
  }
};



export interface RemoveLicenseResponse {
  error?: string;
  success?: string;
}
export const removeLicense  = async (license_id:string): Promise<RemoveLicenseResponse> => {
  try {
    await axiosNestInstance.post("/license/remove-license", { license_id });
    return { success: "Licença removida com sucesso" };
  } catch (error:any) {
    const message = error.response.data.message;
    if(error.status === 400) return { error: typeof message === 'string' ? message : message[0]};
    throw error;
  }
};



export interface SetUserLicenseResponse extends RemoveLicenseResponse{}
export const setLicense = async (formData: FormData): Promise<SetUserLicenseResponse> => {
  const body = formDataToObject(formData);
  try {
    await axiosNestInstance.post("/license/set-license", body);
    return { success: "Licença atualizada com sucesso" };
  } catch (error:any) {
    const message = error.response.data.message;
    if(error.status === 400 || error.status === 404 ) return { error: typeof message === 'string' ? message : message[0]};
    throw error;
  }
};
